import React, { useEffect, useState } from "react"
import StylisedText from "../elements/stylised-text"
import BreadcrumbTitle from "../elements/breadcrumb-title"
import { graphql, useStaticQuery } from "gatsby"
import axios from "axios"
import FormComponent from "../elements/forms/form-component"
import classNames from "classnames"

const GetInTouch = ({ data }) => {
	console.log(data);
	const [formData, setFormData] = useState()
	const [formValues, setFormValues] = useState()

	const [count, setCount] = useState(0);

	const pageQueryData = useStaticQuery(PageQuery)

	const { edges } = pageQueryData.allHubspotForm
	// console.log(edges)

	const windowCheck = () => {
		return typeof window !== `undefined` ? true : false
	}

	const initializeHubspotForm = () => {
		if ('hbspt' in window) {

			if (window.hbspt.forms === undefined || window.hbspt.forms === null) return;

			try {
				window.hbspt.forms.create({
					region: "eu1",
					portalId: "25395670",
					formId: data.formGuid,
					target: "#getInTouch__formwrapper"
				});
			} catch (error) {
				return;
			}

		} else {
			if (count < 3) {
				setTimeout(this.initializeHubspotForm, 500);
				setCount(count + 1);
			}
		}
	}

	useEffect(() => {
		if (data.formGuid == null) {
			data.formGuid = "807896cd-f62b-40b4-9c80-e839840d168b";
		}
		if (windowCheck()) {
			try {
				initializeHubspotForm();
			} catch (error) {
				return;
			}
		}
	}, [])

	return (
		<section className={classNames("getInTouch", data.variation || "standard")}>
			<div className="getInTouch__container">
				<div className="getInTouch__textwrapper">
					<BreadcrumbTitle data={data.breadcrumbTitle} />
					<StylisedText data={data.title} type="h3" />
					<p className="getInTouch__text">{data.description}</p>
					<br></br>
					<a
						href="https://meetings-eu1.hubspot.com/jayden-fourie?utm_medium=email&_hsmi=82582900&utm_content=82582900&utm_source=hs_automation"
						target="_blank"
						aria-label="Submit"
						type="submit"
						className="button-link button-link--primary"
					>
						Book a Meeting
					</a>
				</div>
				<div id="getInTouch__formwrapper" className="formComponent getInTouch__formwrapper">
				</div>
			</div>
		</section>
	)
}

export default GetInTouch

const PageQuery = graphql`
  query PageQuery {
    allHubspotForm(limit: 20) {
      edges {
        node {
          portalId
          guid
          name
          formFieldGroups {
            fields {
              label
              name
              required
              fieldType
              hidden
              placeholder
              options {
                label
                value
              }
            }
          }
        }
      }
    }
  }
`
